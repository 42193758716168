import { AiFillHome } from "react-icons/ai";
import { BsPersonFill } from "react-icons/bs";
import { AiFillAppstore } from "react-icons/ai";

const data = [
  { id: 1, link: "#home", icon: <AiFillHome /> },
  { id: 2, link: "#about", icon: <BsPersonFill /> },
  { id: 3, link: "#portfolio", icon: <AiFillAppstore /> },
];

export default data;
